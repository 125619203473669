/* Scrollbar color */
.os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
    background: #000;
}
.os-theme-dark > .os-scrollbar:hover > .os-scrollbar-track > .os-scrollbar-handle {
    background: #000;
}
.os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle.active {
    background: #000;
}

/* Always hide horizontal scrollbars, horizontal scrollbar handles and scrollbar corners on all devices */
.os-theme-dark > .os-scrollbar-horizontal {
    right: 0px;
    height: 0px;
}
.os-theme-dark.os-host-rtl > .os-scrollbar-horizontal {
    left: 0px;
    right: 0;
}
.os-theme-dark > .os-scrollbar-corner {
    height: 0px;
    width: 0px;
}
.os-theme-dark > .os-scrollbar-corner {
    background-color: transparent;
}
.os-theme-dark > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle {
    min-width: 0px;
}

/* Hide vertical scrollbars on other devices than desktops, regardless of the viewport size */
@media not screen and (hover: hover) and (pointer: fine) {
    .os-theme-dark > .os-scrollbar-vertical {
        bottom: 0px;
        width: 0px;
    }
    .os-theme-dark > .os-scrollbar {
        padding: 0px;
        box-sizing: border-box;
        background: transparent;
    }
    .os-theme-dark > .os-scrollbar.os-scrollbar-unusable {
        background: transparent;
    }
    .os-theme-dark > .os-scrollbar > .os-scrollbar-track {
        background: transparent;
    }
    .os-theme-dark > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle {
        min-height: 0px;
    }
    .os-scrollbar-auto-hidden {
        visibility: unset;
    }
}
